html,
body {
  position: relative;
}

.rel,
.relative {
  position: relative;
}

.abs,
.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}
