body {
  margin: 0;
}

.container {
  margin: 0 auto;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.m-5 {
  margin: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-v-5 {
  margin: 5px 0;
}

.m-h-5 {
  margin: 0 5px;
}

.m-10 {
  margin: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-v-10 {
  margin: 10px 0;
}

.m-h-10 {
  margin: 0 10px;
}

.m-15 {
  margin: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-v-15 {
  margin: 15px 0;
}

.m-h-15 {
  margin: 0 15px;
}

.m-20 {
  margin: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-v-20 {
  margin: 20px 0;
}

.m-h-20 {
  margin: 0 20px;
}

.m-25 {
  margin: 25px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-v-25 {
  margin: 25px 0;
}

.m-h-25 {
  margin: 0 25px;
}

.m-30 {
  margin: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-v-30 {
  margin: 30px 0;
}

.m-h-30 {
  margin: 0 30px;
}

.m-35 {
  margin: 35px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-v-35 {
  margin: 35px 0;
}

.m-h-35 {
  margin: 0 35px;
}

.m-40 {
  margin: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-v-40 {
  margin: 40px 0;
}

.m-h-40 {
  margin: 0 40px;
}

.m-45 {
  margin: 45px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-v-45 {
  margin: 45px 0;
}

.m-h-45 {
  margin: 0 45px;
}

.m-50 {
  margin: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-v-50 {
  margin: 50px 0;
}

.m-h-50 {
  margin: 0 50px;
}

.m-55 {
  margin: 55px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-v-55 {
  margin: 55px 0;
}

.m-h-55 {
  margin: 0 55px;
}

.m-60 {
  margin: 60px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-v-60 {
  margin: 60px 0;
}

.m-h-60 {
  margin: 0 60px;
}

.m-65 {
  margin: 65px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-v-65 {
  margin: 65px 0;
}

.m-h-65 {
  margin: 0 65px;
}

.m-70 {
  margin: 70px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-v-70 {
  margin: 70px 0;
}

.m-h-70 {
  margin: 0 70px;
}

.m-75 {
  margin: 75px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-v-75 {
  margin: 75px 0;
}

.m-h-75 {
  margin: 0 75px;
}

.m-80 {
  margin: 80px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-v-80 {
  margin: 80px 0;
}

.m-h-80 {
  margin: 0 80px;
}

.no-m {
  margin: 0;
}

.no-m-t {
  margin-top: 0;
}

.no-m-r {
  margin-right: 0;
}

.no-m-b {
  margin-bottom: 0;
}

.no-m-l {
  margin-left: 0;
}

.no-m-v {
  margin: 0 0;
}

.no-m-h {
  margin: 0 0;
}

.no-margin {
  margin: 0;
}

.no-m-lr,
.no-m-rl {
  margin-left: 0;
  margin-right: 0;
}

.no-m-tb,
.no-m-bt {
  margin-top: 0;
  margin-bottom: 0;
}

.m-t-only {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.m-b-only {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.m-l-only {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.m-r-only {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.ls-0 {
  margin-left: 10px;
  margin-right: 10px;
}
