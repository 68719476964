.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}
