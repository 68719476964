html,
body {
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 16px;
}

body {
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.lh-1 {
  line-height: 1;
}

.lh-13 {
  line-height: 1.3;
}

.lh-15 {
  line-height: 1.5;
}

.bold,
.strong {
  font-weight: bold;
}

.no-bold {
  font-weight: normal;
}

.italic,
.em {
  font-style: italic;
}

.strike {
  text-decoration: line-through;
}

.normal {
  font-weight: normal;
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}

.f9 {
  font-size: 9px;
}

.f10 {
  font-size: 10px;
}

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f17 {
  font-size: 17px;
}

.f18 {
  font-size: 18px;
  line-height: 30px;
}

.f19 {
  font-size: 19px;
}

.f20 {
  font-size: 20px;
}

.f30 {
  font-size: 30px;
}

.f40 {
  font-size: 40px;
}

.f50 {
  font-size: 50px;
}

.f60 {
  font-size: 60px;
}

.size-small {
  font-size: 75%;
}

.size-normal {
  font-size: 100%;
}

.size-medium {
  font-size: 125%;
}

.size-big,
.size-large {
  font-size: 150%;
}

.size-huge {
  font-size: 200%;
}

.inherit {
  font: inherit;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.text-rgb-2 {
  color: rgba(0, 0, 0, 0.2);
}

.text-rgb-3 {
  color: rgba(0, 0, 0, 0.3);
}

.text-rgb-4 {
  color: rgba(0, 0, 0, 0.4);
}

.text-rgb-5 {
  color: rgba(0, 0, 0, 0.5);
}

.no-ul,
.no-ul:hover,
.no-ul a,
.no-ul a:hover {
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap;
}

.auto-cell-size {
  white-space: nowrap;
  width: 1%;
}

.ls-0 {
  letter-spacing: -3px;
  white-space: nowrap;
}
