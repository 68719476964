.block {
  display: block;
}

.inline {
  display: inline;
}

.in-block {
  display: inline-block;
  zoom: 1;
}

.d-none,
.hide,
.hidden {
  display: none;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.tb-0 {
  top: 0;
  bottom: 0;
}

.lr-0 {
  left: 0;
  right: 0;
}
