@import "~@angular/material/theming";

@mixin fixed-deposits-account-view-component-theme($theme) {
  $primary: map-get($theme, primary);
  mifosx-fixed-deposit-account-view {
    .fixed-deposits-account-card {
      .header {
        background-color: mat-color($primary, 500);
      }
      .table-headers {
        background-color: mat-color($primary, 500);
        color: mat-contrast($primary, 500);
      }
    }
  } 
}
