@import "~@angular/material/theming";

@mixin dashboard-component-theme($theme) {
  $primary: map-get($theme, primary);
  
  mifosx-dashboard {
    
    .recent-activities, .frequent-activities {
      .header {
        background-color: mat-color($primary, 500);
        color: mat-contrast($primary, 500);
      }
    }

    mifosx-amount-collected-pie,
    mifosx-amount-disbursed-pie,
    mifosx-client-trends-bar  {
      .card {
        .header {
          background-color: mat-color($primary, 500);
          color: mat-contrast($primary, 500);
          mat-label,
          .mat-select-value,
          .mat-select-arrow {
            color: mat-contrast($primary, 500);
          }
          .mat-form-field-underline,
          .mat-form-field-ripple {
            background-color: mat-contrast($primary, 500);
          }
        }
      }
    }

  }
}
