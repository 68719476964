.v-mid,
.v-m {
  vertical-align: middle;
}

.v-top,
.v-t {
  vertical-align: top;
}

.v-bottom,
.v-b {
  vertical-align: bottom;
}

.v-super {
  vertical-align: super;
}
