html,
body {
  overflow: hidden;
}

.overflow-x-scroll {
  max-width: 100%;
  overflow-x: auto;
}

.overflow-no {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}
